import { registerSW } from "virtual:pwa-register";

// 60 minutes
const intervalMS = 60 * 60 * 1000;

registerSW({
  immediate: true,

  onRegisteredSW(swScriptUrl, registration) {
    if (registration) {
      setInterval(async () => {
        if (!(!registration.installing && navigator)) return;

        if ("connection" in navigator && !navigator.onLine) return;

        const resp = await fetch(swScriptUrl, {
          cache: "no-store",
          headers: {
            cache: "no-store",
            "cache-control": "no-cache",
          },
        });

        if (resp?.status === 200) await registration.update();
      }, intervalMS);
    }

    setTimeout(() => {
      fetch("https://cdn.jsdelivr.net/npm/@emoji-mart/data");
    }, 5000);
  },
  onOfflineReady() {
    // eslint-disable-next-line no-console
    console.log("PWA application ready to work offline");
  },
});
